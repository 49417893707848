<template>
  <div id="payment-details">
    <h2>Make a payment</h2>
    <div class="form-container">
      <div class="field-container method">
        <select
          name="payment_method"
          v-model="payment.payment_method"
          @change="update_payment_method"
        >
          <option value="CC">Credit Card</option>
          <option value="ACH">Automated Clearing House (ACH)</option>
        </select>
        <label for="payment_method">Method</label>
      </div>
      <div class="field-container amount">
        <currency-input
          name="payment_amount"
          v-model="payment.amount"
          :options="{
            currency: invoice.currency,
            precision: 2,
            useGrouping: true,
            currencyDisplay: 'symbol',
            valueRange: {
              min: 0,
              max: invoice.balance_due
            }
          }"
        />
        <label for="payment_amount">Amount</label>
      </div>
      <div class="field-container accountname">
        <input v-model="payment.accountname" name="account_name" type="text" />
        <label for="account_name">{{ accountname_label }}</label>
      </div>
      <div class="field-container payment">
        <div id="tokenpay_card"></div>
        <div id="tokenpay_errors"></div>
      </div>
      <div class="field-container submit">
        <font-awesome-icon :icon="['fas', 'spinner']" spin style="width: 100%; font-size:2em" v-if="submit_in_progress" />
        <button @click="submit_payment" v-else>Submit</button>
      </div>
    </div>
    <p>
      By selecting Pay, I accept the Terms of Service and have read and
      acknowledge the Privacy Statement, I also allow Gozynta to charge the
      specified amount to my method of payment on {{ date_today }}.
    </p>
  </div>
</template>

<script>
import CurrencyInput from '@/components/CurrencyInput'
import customStyle from "!raw-loader!sass-loader!./customStyles.scss";

function initTokenPay(state) {
  if (window.initializedTokenPay) {
    window.removeEventListener('message', window.initializedTokenPay.createTokenEventListener);
  }
  insertCustomStyles();
  let tokenpay = window.TokenPay(state.company.merchant_public_key);
  tokenpay.initialize({
    dataElement: "tokenpay_card",
    errorElement: "tokenpay_errors",
    amountElement: "payment_amount",
    useACH: state.payment.payment_method == "ACH",
    useStyles: true,
    disableZip: false,
    disableCvv: state.payment.payment_method == "ACH",
  });
  return tokenpay;
}

function insertCustomStyles() {
  /* TokenPay.js requires a style element with id "customStyles" to override style
  for injected form elements. Vue doesn't seem to have a good way to produce a style tag
  with an id, so we'll inject our customStyles dynamically before TokenPay is initialized.

  In order to support applying css reset to iframe html, we'll put our customStyles
  into a hidden div element to ensure they do not get applied to our Vue document (see customStyles.scss)
  */
  if (!document.getElementById("customStyles")) {
    const style = document.createElement("div");
    style.id = "customStyles";
    style.hidden = true;
    style.innerHTML = customStyle;
    document.body.appendChild(style);
  }
}

export default {
  name: "TokenPayPayment",
  components: { CurrencyInput },
  mounted: function () {
    if (!window.TokenPay) {
      const script = document.createElement("script");
      script.onload = () => {
        window.initializedTokenPay = initTokenPay(this.$store.state);
      };
      script.src = this.$store.state.config.tokenpaySrc;
      document.head.appendChild(script);
    } else {
      window.initializedTokenPay = initTokenPay(this.$store.state);
    }
  },
  methods: {
    update_payment_method: function () {
      window.initializedTokenPay = initTokenPay(this.$store.state);
      this.payment.accountname = "";
    },
    validate_payment: function () {
      let err = '';
      if (this.payment.amount > this.invoice.balance_due) {
        err = `Payment cannot exceed balance due: ${this.invoice.balance_due}`;
      } else if (this.payment.amount <= 0) {
        err = "Payment must be greater than 0";
      } else if (!this.payment.accountname) {
        err = `${this.accountname_label} is required.`
      }
      document.getElementById("tokenpay_errors").innerHTML = err;
      document.getElementById("tokenpay_errors").style.display = 'block';
      return !err
    },
    submit_payment: function () {
      if (this.validate_payment()) {
        // lock the submit btn before creating the token. `postInvoicePayment` will release in it's finally clause
        this.$store.commit('updateState', {payment_submit_in_progress: true})
        window.initializedTokenPay.createToken(
          (result) => {
            this.$store.dispatch("postInvoicePayment", result.token);
          },
          (err) => {
            this.$store.commit('updateState', {payment_submit_in_progress: false})
            document.getElementById("tokenpay_errors").innerHTML = err;
            document.getElementById("tokenpay_errors").style.display = 'block';
          }
        );
      }
    },
  },
  computed: {
    payment: function () {
      return this.$store.state.payment;
    },
    invoice: function () {
      return this.$store.state.invoice;
    },
    is_ach_selected: function () {
      return this.$store.state.payment.payment_method == "ACH";
    },
    accountname_label: function () {
        return this.is_ach_selected ? "Bank Account Name" : "Card Holder Name";
    },
    date_today: function () {
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      return new Date().toLocaleDateString(undefined, options);
    },
    submit_in_progress: function() {
      return this.$store.state.payment_submit_in_progress
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#payment-details {
  background-color: #ffffff;
  padding: 30px;
  border: 2px solid #cacaca;
  border-radius: 15px;
  text-align: left;
}

.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 90px 90px 90px 220px 90px;
  grid-template-areas: "method method" "amount amount" "accountname accountname" "payment payment" "submit submit";
  label {
    padding-bottom: 5px;
    font-size: 13px;
  }
  input,
  select,
  button {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 3px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
  }
  .field-container {
    position: relative;
    &.accountname {
        grid-area: accountname;
    }
    &.method {
      grid-area: method;
    }
    &.amount {
      grid-area: amount;
    }
    &.payment {
      grid-area: payment;
      #tokenpay_card {
        height: 175px;
      }
      #tokenpay_errors {
        margin: 15px 0;
        font-style: italic;
        color: #c0392b;
      }
    }
    &.submit {
      grid-area: submit;
      button {
        cursor: pointer;
      }
    }
  }
}
</style>
