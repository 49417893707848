<template>
  <div class="container" v-if="branding">
    <div slot="header" id="header">
      <img :src="branding.logo" class="float-left" />
    </div>

    <slot></slot>

    <div slot="footer" id="footer">
      <div>
        <a href="https://www.gozynta.com/payments/">Powered by Gozynta</a> | <a href="https://www.gozynta.com/terms">Terms</a> |
        <a href="https://www.gozynta.com/privacy/">Privacy</a>
      </div>
      <div>
        <img :src="branding.logo" />
      </div>
      <span v-html="footer_info"></span>
    </div>
  </div>

  <div v-else>
    <NotFoundPage v-if="branding === false" />
  </div>
</template>

<script>
import NotFoundPage from "@/pages/NotFoundPage.vue";

export default {
  name: "Branding",
  components: {
    NotFoundPage,
  },
  computed: {
    branding: function () {
      return this.$store.state.branding;
    },
    footer_info: function () {
        let result = ""
        if (this.branding.mailing_address) {
            result += this.branding.mailing_address;
        }
        if (this.branding.website) {
            if (result) {
                result += ' | ';
            }
            result += `<a href="${this.branding.website}">${this.branding.website}</a>`;
        }
        if (this.branding.phone) {
            if (result) {
                result += ' | '
            }
            result += this.branding.phone
        }
        return result
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  width: 100%;
}

#header > img {
  width: 200px;
}

#footer img {
  width: 200px;
}
</style>
