<template>
  <div v-if="invoice">
    <div id="invoice-details">
      <h1 class="balance-due">{{ format_currency(invoice.balance_due) }}</h1>
      <div id="client-details">
        <p>
          <span style="width: 60px; display: inline-block">From</span
          >{{ company.name }}
        </p>
        <p>
          <span style="width: 60px; display: inline-block">To</span
          >{{ invoice.bill_to_address.name }}
        </p>
        <p>
          <span style="width: 60px; display: inline-block">Due</span
          >{{ invoice_due_date }}
        </p>
      </div>
    </div>

    <TokenPayPayment v-if="is_tokenpay_merchant" />
  </div>
  <div v-else>
    <NotFoundPage v-if="invoice === false" />
  </div>
</template>

<script>
// @ is an alias to /src
import TokenPayPayment from "@/components/payments/tokenpay/TokenPayPayment.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";

export default {
  name: "Invoice",
  components: {
    NotFoundPage,
    TokenPayPayment,
  },
  methods: {
    format_currency: function(value) {
        var formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: this.invoice.currency,
        });

        return formatter.format(value) + ' ' + this.invoice.currency;
    }
  },
  computed: {
    invoice: function () {
      return this.$store.state.invoice;
    },
    company: function () {
      return this.$store.state.company;
    },
    is_tokenpay_merchant: function () {
      return this.$store.state.company.payment_gateway == "TokenPay";
    },
    invoice_due_date: function() {
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      // Feeding in undefined defaults to the browser's local timezone.
      let d = new Date(this.invoice.due_date);
      return new Date(d.getTime() + Math.abs(d.getTimezoneOffset()*60000)).toLocaleDateString(undefined, options);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#invoice-details {
  background-color: #ffffff;
  padding: 30px;
  border: 2px solid #cacaca;
  border-radius: 15px;
  text-align: left;
}

.balance-due {
  margin: 0px;
}

.balance-due-date {
  margin: 10px 0px;
  color: #666666;
}

div {
  margin: 10px 0px;
}
</style>
