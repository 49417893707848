<template>
  <div>
    <Branding>
      <Confirmation />
    </Branding>
  </div>
</template>

<script>
// @ is an alias to /src
import Branding from "@/components/Branding.vue";
import Confirmation from "@/components/Confirmation.vue";

export default {
  name: "ConfirmationPage",
  components: {
    Branding,
    Confirmation,
  },
};
</script>
