<template>
  <div>
    <Branding>
      <Invoice />
    </Branding>
  </div>
</template>

<script>
// @ is an alias to /src
import Branding from "@/components/Branding.vue";
import Invoice from "@/components/Invoice.vue";

export default {
  name: "InvoicePage",
  components: {
    Branding,
    Invoice,
  },
};
</script>
