<template>
  <div class="container">
    <div id="header">
      <img :src="branding.logo" />
    </div>
    <div id="invoice-details">
      <div id="customer-details" class="float-left">
        <h3>Customer</h3>
        <p>
          {{ invoice.bill_to_address.name }}<br />
          {{ invoice.bill_to_address.address }},<br />
          {{ invoice.bill_to_address.locality }},
          {{ invoice.bill_to_address.region }}<br />
          {{ invoice.bill_to_address.postal_code }}
        </p>
      </div>
      <div id="paid-to-details" class="float-right">
        <h3>Paid to</h3>
        <p>
          {{ branding.name }}<br />
          {{ branding.mailing_address }}
        </p>
      </div>
    </div>
    <div id="payment-information">
      <div class="clearfix">
        <h3 class="float-left">Payment information</h3>
        <br />
      </div>
      <div>
        <hr class="solid" />
        <p>
          <span class="float-left">Date</span
          ><span class="float-right">{{ transaction_date }}</span>
        </p>
        <br />
        <hr class="solid" />
        <p>
          <span class="float-left">Previous Balance</span
          ><span class="float-right"
            >{{ format_currency(transaction.authorized_amount + invoice.balance_due) }}</span
          >
        </p>
        <br />
        <hr class="solid" />
        <p>
          <span class="float-left">Amount Charged</span
          ><span class="float-right">{{ format_currency(transaction.authorized_amount) }}</span>
        </p>
        <br />
        <hr class="solid" />
        <p>
          <span class="float-left">New Balance</span
          ><span class="float-right"
            >{{ format_currency(invoice.balance_due) }}</span
          >
        </p>
        <br />
        <hr class="solid" />
        <p>
          <span class="float-left">Payment Method</span
          ><span class="float-right">{{ transaction.masked_ccard || "ACH" }}</span>
        </p>
        <br />
        <hr class="solid" />
        <p>
          <span class="float-left">Transaction ID</span
          ><span class="float-right">{{ transaction.transaction_id }}</span>
        </p>
        <br />
        <hr class="solid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Receipt",
  props: {
      transaction: Object
  },
  methods: {
    format_currency: function(value) {
        var formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: this.invoice.currency,
        });

        return formatter.format(value) + ' ' + this.invoice.currency;
    }
  },
  computed: {
    invoice: function () {
      return this.$store.state.invoice;
    },
    branding: function () {
      return this.$store.state.branding;
    },
    transaction_date: function () {
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      let d = new Date(this.transaction.transaction_date)
      return new Date(d.getTime() + Math.abs(d.getTimezoneOffset()*60000)).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header > img {
  width: 200px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 30px;
  width: 600px;
  margin: auto;
}

.container > div {
  width: 600px;
}

.balance-due {
  margin: 0px;
}

.balance-due-date {
  margin: 10px 0px;
  color: #666666;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

#customer-details,
#paid-to-details {
  text-align: left;
}

hr.solid {
  margin-top: 15px;
}

div {
  margin: 10px 0px;
}
</style>
