<template>
  <div v-if="invoice && error">
    <div id="invoice-details">
      <div class="error-header">
        <font-awesome-icon :icon="['fas', 'times-circle']" />
      </div>
      <div class="error-body">
        <h1 class="balance-failed">{{ format_currency(payment.amount) }} Payment Failed</h1>
        <div>
          <p>
            <span>Response from Gateway</span
            ><span class="float-right">{{ error.message }}</span>
          </p>
        </div>
        <hr class="solid" />
        <div>
          <p>
            <span>Due date</span
            ><span class="float-right">{{ invoice_due_date }}</span>
          </p>
          <p>
            <span>Invoice total</span
            ><span class="float-right">{{ format_currency(invoice.total) }}</span>
          </p>
          <p>
            <span>Remaining balance</span
            ><span class="float-right">{{ format_currency(invoice.balance_due) }}</span>
          </p>
        </div>
      </div>
      <div class="error-footer">
          <button class="primary-btn" v-on:click="makeAnotherPayment" v-if="invoice.balance_due > 0">
          Make another Payment
        </button>
      </div>
    </div>
  </div>

  <div v-else>
    <NotFoundPage />
  </div>
</template>

<script>
import NotFoundPage from "@/pages/NotFoundPage.vue";

export default {
  name: "Error",
  components: {
    NotFoundPage,
  },
  methods: {
    makeAnotherPayment: function () {
      this.$router.push({name: "InvoicePage", params: {id: this.invoice.id }})
    },
    format_currency: function(value) {
        var formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: this.invoice.currency,
        });

        return formatter.format(value) + ' ' + this.invoice.currency;
    }
  },
  computed: {
    invoice: function () {
      return this.$store.state.invoice;
    },
    payment: function () {
      return this.$store.state.payment;
    },
    error: function () {
      let error = this.$store.state.errors.find(
        item => item.error_id == this.$router.currentRoute.value.params.error_id
      );

      return error
    },
    invoice_due_date: function() {
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      // Feeding in undefined defaults to the browser's local timezone.
      let d = new Date(this.invoice.due_date);
      return new Date(d.getTime() + Math.abs(d.getTimezoneOffset()*60000)).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#invoice-details {
  background-color: #ffffff;
  text-align: left;
}

.error-header {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #cf2a27;
  color: #fff;
  font-size: 6em;
  padding: 10px 0px 5px 0px;
}

.error-body {
  padding: 0px 30px 20px 30px;
}

.balance-failed {
  margin: 0px 0px 40px 0px;
  color: #cf2a27;
  text-align: center;
  font-size: 3em;
}

div {
  margin: 10px 0px;
}

.primary-btn {
  background-color: #cf2a27;
  color: #fff;
  border: 2px solid #000;
  padding: 10px 40px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 1);
  display: inline-block;
  margin: 0 10px;
}

.error-footer {
  text-align:center;
  padding-bottom: 30px;
}
</style>
