<template>
  <div v-if="invoice && transaction">
    <div id="invoice-details">
      <div class="confirmation-header">
        <font-awesome-icon :icon="['fas', 'check-circle']" />
      </div>
      <div class="confirmation-body">
        <h1 class="balance-paid">
          You paid {{ format_currency(transaction.authorized_amount) }}
        </h1>
        <div>
          <p>
            <span>Payment method</span
            ><span class="float-right">{{ transaction.masked_ccard || "ACH" }}</span>
          </p>
          <p>
            <span>Transaction ID</span
            ><span class="float-right">{{ transaction.transaction_id }}</span>
          </p>
          <p>
            <span>Transaction Date</span
            ><span class="float-right">{{ transaction_date }}</span>
          </p>
        </div>
        <hr class="solid" />
        <div>
          <p>
            <span>Invoice</span
            ><span class="float-right">#{{ invoice.invoice_number }}</span>
          </p>
          <p>
            <span>Due date</span
            ><span class="float-right">{{ invoice_due_date }}</span>
          </p>
          <p>
            <span>Invoice total</span
            ><span class="float-right">{{ format_currency(invoice.total) }}</span>
          </p>
          <p>
            <span>Remaining balance</span
            ><span class="float-right"
              >{{ format_currency(invoice.balance_due) }}</span
            >
          </p>
        </div>
        <div style="text-align: center">
          <Vue3Html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            :filename="`INV${invoice.invoice_number}-${transaction.transaction_id}`"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-orientation="portrait"
            ref="html2Pdf"
          >
            <template  v-slot:pdf-content>
                <Receipt :transaction="transaction" />
            </template>
          </Vue3Html2pdf>
          <button class="primary-btn" v-on:click="generateReport">
            Download receipt
          </button>
          <button class="primary-btn" v-on:click="makeAnotherPayment" v-if="invoice.balance_due > 0">
              Make another Payment
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <NotFoundPage />
  </div>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf'
import Receipt from "./Receipt";
import NotFoundPage from "../pages/NotFoundPage";

export default {
  name: "Confirmation",
  methods: {
    generateReport: function () {
      this.$refs.html2Pdf.generatePdf();
    },
    makeAnotherPayment: function () {
      this.$router.push({name: "InvoicePage", params: {id: this.invoice.id }})
    },
    format_currency: function(value) {
        var formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: this.invoice.currency,
        });

        return formatter.format(value) + ' ' + this.invoice.currency;
    }
  },
  computed: {
    invoice: function () {
      return this.$store.state.invoice;
    },
    invoice_due_date: function() {
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      // Feeding in undefined defaults to the browser's local timezone.
      let d = new Date(this.invoice.due_date);
      return new Date(d.getTime() + Math.abs(d.getTimezoneOffset()*60000)).toLocaleDateString(undefined, options);
    },
    transaction: function () {
      let transaction = this.$store.state.posted_payments.find(
        item => item.transaction_id == this.$router.currentRoute.value.params.transaction_id
      );

      return transaction
    },
    transaction_date: function() {
      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      // Feeding in undefined defaults to the browser's local timezone.
      let d = new Date(this.transaction.transaction_date);
      return new Date(d.getTime() + Math.abs(d.getTimezoneOffset()*60000)).toLocaleDateString(undefined, options);
    }
  },
  components: {
    Receipt,
    Vue3Html2pdf,
    NotFoundPage,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#invoice-details {
  background-color: #ffffff;
  text-align: left;
}

.confirmation-header {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #009e0f;
  color: #fff;
  font-size: 6em;
  padding: 10px 0px 5px 0px;
}

.confirmation-body {
  padding: 0px 30px 20px 30px;
}

.balance-paid {
  margin: 0px 0px 40px 0px;
  color: #009e10;
  text-align: center;
  font-size: 3em;
}

.primary-btn {
  background-color: #009e0f;
  color: #fff;
  border: 2px solid #000;
  padding: 10px 40px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 1);
  display: inline-block;
  margin: 0 10px;
}

div {
  margin: 10px 0px;
}
</style>
